import { render, staticRenderFns } from "./MiniBar.vue?vue&type=template&id=1ff3f2b1&scoped=true&"
import script from "./MiniBar.vue?vue&type=script&lang=js&"
export * from "./MiniBar.vue?vue&type=script&lang=js&"
import style0 from "./MiniBar.vue?vue&type=style&index=0&id=1ff3f2b1&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ff3f2b1",
  null
  
)

export default component.exports